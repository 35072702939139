<template>
  <div class="mobile-menu" id="menu-card">
    <img
      src="@/assets/icons/ClosePopup.svg"
      alt
      :class="locale === 'ara' ? 'mobile-menu-close-icon-rtl' : 'mobile-menu-close-icon'"
      @click="closeMenu(false)"
    />
    <div class="mobile-menu-card" v-for="(item, index) in menuList" :key="index">
      <div
        class="mobile-menu-card-content"
        v-if="
          subscriberid
            ? checkForBoldMenu(item) === true
            : !item.isRequiredLogin
        "
        @click="menuSelect(index)"
      >
        <div class="mobile-menu-card-content-item">
          <img :src="item.menuIcon" alt="test" />
          <p>{{ locale === "ara" ? item.title.ara : item.title.eng.toLowerCase() }}</p>
        </div>
      </div>
    </div>
    <div class="mobile-menu-language-toggle" @click="closeMenu(true)">
      <div class="mobile-menu-language-toggle-container">
        <img src="@/assets/icons/Globe1.svg" alt />
        <p>{{ locale === "ara" ? "English" : "عربى" }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { eventBus } from "@/eventBus";
import moment from "moment";
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    menu: {
      type: Array,
    },

    subscriptions: {
      type: Array | Object
    }
  },
  data() {
    return {
      menuList: [],
      subscriptionItemMenu: null,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "subscriptionList","appConfig", "profileid"]),
     
    locale() {
      return this.$i18n.locale;
    },
  },
  created() {
		if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
          this.filterMenuItemsingDecking();
          return;
      }
    this.menuList = this.menu;
		// console.log(this.menuList,"CHECK-1")
    // this.fetchMenuList();
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  methods: {
    closeMenu(state) {
      let response = {
        state: false,
      };

      response.change = state;
      this.$emit("close", response);
    },
    //checking if the age is matching to show bold category.
    checkForBoldMenu(item) {
      if (item.id === "BOLD") {
        if (localStorage.getItem("subscriberDetails")) {
          let details = JSON.parse(localStorage.getItem("subscriberDetails"));

          if (!details.data.dob) return false;

          let isKids = this.isKidsProfile(details.data.dob);

          if (isKids) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },
    //Checking if it is kids mode or not.
    isKidsProfile(dob) {
      let d = new Date();
      let currentYear = d.getFullYear();
      let userYear = moment(dob)
        .format("YYYY-MM-DD")
        .split("-")[0];
      if (currentYear - userYear >= 0) {
        if (currentYear - userYear < 14) {
          return true;
        } else {
          return false;
        }
      }
    },
    fetchMenuList() {
      let menuItemsList = this.appConfig.menu;

      let currentProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if (currentProfileDetails) {
        currentProfileDetails = JSON.parse(currentProfileDetails);
        this.subscriberProfileDetails = currentProfileDetails;
      }

      const menuListArr = [];

      menuItemsList.forEach((menu) => {

        if (this.subscriberid && this.subscriberProfileDetails && this.subscriberProfileDetails.kidsmode === "YES") {
          if (menu.profileTypes && menu.profileTypes.includes("KIDS")) {
            menuListArr.push(menu);
          }
        }

        else {
          if (menu.profileTypes && (menu.profileTypes.includes("ADULT") || menu.profileTypes.includes("MASTER")) ) {
            menuListArr.push(menu);
          }
        }
      });

      if(this.subscriptions && this.subscriptions.length <= 0 && this.profileid !== this.subscriberid) {
        let menuIndex;

        menuListArr.forEach((menuItem, index) => {
          if(menuItem.id === "PLAN") {
            menuIndex = index
          }
        });

        if(menuIndex > -1) {
          menuListArr.splice(menuIndex, 1);
        }
      }

      this.menuList = menuListArr;
    },
		//decking mob menu list
		filterMenuItemsingDecking() {
			//alert("called")
      let dataset = localStorage.getItem("appDeckingConfig") ? JSON.parse(localStorage.getItem("appDeckingConfig")) : '';
      let menuItemsList = dataset.screens;
			console.log("menuItemsList-CHECK-1",menuItemsList)

      let currentProfileDetails = localStorage.getItem(
        "subscriberProfileDetails"
      );

      if (currentProfileDetails) {
        currentProfileDetails = JSON.parse(currentProfileDetails);
        this.subscriberProfileDetails = currentProfileDetails;
      }

      const menuListArr = [];

      menuItemsList.forEach((menu) => {
        if (
          this.subscriberid && 
          this.subscriberProfileDetails &&
          this.subscriberProfileDetails.kidsmode === "YES"
        ) {
          if (menu.profileTypes && (menu.profileTypes.includes("KIDS") || menu.profileTypes.includes("ALL"))) {
            menuListArr.push(menu);
          }
        } else if (
            menu.profileTypes &&
            (menu.profileTypes.includes("ADULT") ||
              menu.profileTypes.includes("MASTER") || menu.profileTypes.includes("ALL"))
          ) {
            menuListArr.push(menu);
          }
        else if ( menu.profileTypes  &&
            (menu.profileTypes.includes("ADULT") ||
              menu.profileTypes.includes("MASTER") || menu.profileTypes.includes("ALL"))) {
                this.itemList.push(menu); 
              } 
      });
      this.menuList = menuListArr;
			console.log("MOB-MENUS",this.menuList)
    },
    //selecting menu category and routing to that component.
    menuSelect(index) {
			if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
			this.menuSelectDecking(index);
			return;
      }
      console.log("menu select==============");
      let currentLanguage = this.getCurrentLanguageUrlBase();

      let menuDetail = this.menuList[index];
			let lowerCaseSection = (menuDetail.id).toLowerCase()

      switch (menuDetail.id) {
        case "HOME":
          this.$router.push({ name: "Home", params: { menuDetail, lang: currentLanguage  } });
          break;
        case "SERIES":
          // this.$router.push({ name: "series", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: lowerCaseSection, lang: currentLanguage  } });
          break;
        case "TVSHOWS":
          // this.$router.push({ name: "series", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: lowerCaseSection , lang: currentLanguage } });
          break;
        // case "TVSHOW":
        //   this.$router.push({ name: "Tvshows" });
        //   break;
        case "MOVIES":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: lowerCaseSection, lang: currentLanguage } });
          break;
        case "KIDS":
          // this.$router.push({ name: "kids", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: lowerCaseSection, lang: currentLanguage  } });
          break;
        case "WATCHLIST":
          this.$router.push({ name: "Watchlist", params: { menuDetail, lang: currentLanguage  } });
          break;
        case "PLAN":
          console.log("in plan option===============");
          let payload = { listType: "onlySubscribe" };
          // eventBus.$emit("subscribePopup", payload);
          // this.$router.push("/viewAllPlans");
          this.$router.push({ name: "seeAllPlans", params: { menuDetail, lang: currentLanguage } });

          break;
        case "HOME-KIDS":
          this.$router.push({ name: "Home", params: { menuDetail, lang: currentLanguage  } });
          break;
      }

      this.closeMenu();
    },
			 menuSelectDecking (index) {
      if (this.isLanguageSwitchEnabled == true) {
        this.isLanguageSwitchEnabled = false;
      }
      let currentLanguage = this.getCurrentLanguageUrlBase();
      let moreMenuDetail = this.menuList[index]
       let sectionTitle = moreMenuDetail.title.eng.replaceAll(' ','').toLowerCase();
       if (sectionTitle == 'home') {
        this.$router.push({
            name: "Home",
            params: {lang: currentLanguage},
          });
       }else if(sectionTitle == 'mylist'){
				  this.$router.push({ name: "Watchlist", params: { moreMenuDetail, lang: currentLanguage } });
			 }else if(sectionTitle == 'subscriptions'){
				let payload = { listType: "onlySubscribe" };
          localStorage.setItem("payment_source", "Menu");
          // eventBus.$emit("subscribePopup", payload);
          // this.$router.push("/viewAllPlans");
          this.$router.push({ name: "seeAllPlans", params: { moreMenuDetail, lang: currentLanguage } });
			 }
			  else {
        this.$router.push({
            name: "section-screen",
            params: { section: sectionTitle, lang: currentLanguage },
          });
       }
   
          this.hideList = false;
					this.closeMenu();
 
    },
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
  mixins: [Utility]
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";

.mobile-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  background-color: $clr-dark-gd2;
  animation: menu 0.5s;
  padding-top: 20px;
  &-close-icon-rtl {
    position: absolute;
    left: 5%;
    top: 2.5%;
  }
  &-close-icon {
    position: absolute;
    right: 5%;
    top: 2.5%;
  }
  &-card {
    width: 100%;
    &-content {
      width: 100%;
      height: 100%;
      padding: 1rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      &-item {
        display: flex;
        img {
          width: 20px;
        }
        p {
          margin: 0px 10px;
          color: #ffffff;
          font-family: $font-regular;
          font-weight: 600;
          font-size: 0.9rem;
          text-transform: uppercase;
          line-height: 35px;
        }
      }
    }
  }
  &-language-toggle {
    padding: 1rem;
    // border-bottom: 1px solid rgba(255,255,255,0.2);
    &-container {
      width: 40%;
      height: 50px;
      display: flex;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 5px;
      padding: 10px;
      img {
        width: 20px;
      }
      p {
        margin: 0 10px;
        color: #ffffff;
        font-family: $font-regular;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 28px;
        text-transform: uppercase;
      }
    }
  }
}
@media (orientation: landscape) {
  .mobile-menu {
    height: 80vh !important;
  }
}
@keyframes menu {
  from {
    left: -600px;
  }
  to {
    left: 0px;
  }
}
</style>
